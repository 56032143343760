function isAOSP(): boolean {
    // http://stackoverflow.com/questions/14403766/how-to-detect-the-stock-android-browser
    const maybeAndroid = navigator.userAgent.includes('Android');
    const webkitVer = parseInt(
        /WebKit\/(\d+)|$/.exec(navigator.userAgent)[1],
        10
    ); // undefined if not found
    return (
        maybeAndroid &&
        webkitVer <= 534 &&
        navigator.vendor.indexOf('Google') === 0
    );

    // /android.+samsungbrowser\/([\w\.]+)/i,
    // /android.+version\/([\w\.]+)\s+(?:mobile\s?safari|safari)*/i
}

function _supportsWebSockets(): boolean {
    // exclude Android Stock Browser (Android <= 4.4)
    return !!window['WebSocket'] && !isAOSP();
}

function _supportsFileReader(): boolean {
    return !!window['FileReader'];
}

export const supportsWebSockets: boolean = _supportsWebSockets();
export const supportsFileReader: boolean = _supportsFileReader();
