(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("xmlentities"));
	else if(typeof define === 'function' && define.amd)
		define(["xmlentities"], factory);
	else if(typeof exports === 'object')
		exports["vchatCore"] = factory(require("xmlentities"));
	else
		root["vchatCore"] = factory(root["xmlentities"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__82__) {
return 