import { ChatHandler, Query } from './definitions';
import { ExitCode } from './exit-code';
import { SourceSet } from './sources/source-set';

export class Handler implements ChatHandler {
    private et: EventTarget;
    constructor(et: EventTarget) {
        this.et = et;
    }
    public onChatStop(exitCode: ExitCode, exitMessage?: string): void {
        this.dispatch('chatStop', { exitCode, exitMessage });
    }
    public onChatPause: () => void;
    public onChatPauseHandler(): void {
        this.dispatch('chatPause', {});
    }
    public onChatResume(sourceSet: SourceSet): void {
        this.dispatch('chatResume', { sourceSet });
    }
    public onMessage(
        text: string,
        from: string,
        key: string,
        params: object
    ): void {
        this.dispatch('message', { text, from, key, params });
    }
    public onAbilityUpdate(name: string, value: boolean): void {
        this.dispatch('abilityUpdate', { name, value });
    }
    public onQuery(query: Query): void {
        this.dispatch('query', { query });
    }
    public onSingleModeUpdate(value: boolean): void {
        this.dispatch('singleModeUpdate', { name: 'singleMode', value });
    }
    public onTextMuteUpdate(value: boolean): void {
        this.dispatch('textMuteUpdate', { name: 'textMute', value });
    }
    public onAudioMuteUpdate(value: boolean): void {
        this.dispatch('audioMuteUpdate', { name: 'audioMute', value });
    }
    public onLimitUpdate(param: string, value: number): void {
        this.dispatch('limitUpdate', { param, value });
    }
    private dispatch(t: string, values: any): void {
        const event = new Event(t);
        for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
                event[key] = values[key];
            }
        }
        this.et.dispatchEvent(event);
    }
}
